<template>

    <div class="container">
        <div class="login">
            <label>

                <div>
                    Name used in account
                </div>
                <input v-model="data.first_name" placeolder="Name/Nombre" required>
            </label>
            <label>
                <div>
                    Last name used in account
                </div>

                <input v-model="data.last_name" placeolder="Last Name/Apellido" required>
            </label>
            <label>
                <div>
                    Please input your whatsapp number with country code, no spaces ( Mexico: +521 )
                </div>
                <input v-model="data.cellphone" placeholder="Whatsapp: +5215544332211" required>
            </label>

            <button @click.stop="request_login_credentials()">Send username and password/Enviar usuario y contraseña</button>
        </div>


    </div>

</template>

<script setup>

    import axios from 'axios'
    import {reactive} from 'vue'

    let data=reactive({
        first_name:'',
        last_name:'',
        cellphone:''
    })

    async function request_login_credentials() {

        for (let key in data){
            if (['',null," "].indexOf(data[key])>-1){
                alert('Please fill all fields')
                return
            }
        }

        let response=await axios.post('/reset/request_login_credentials/',{cellphone:data.cellphone,first_name:data.first_name,last_name:data.last_name})
        let twilio_continue_statuses=[
                            "sent",
                            "delivered",
                            "accepted",
                            "read",
                        ]

        let status=response.data.status
        if(twilio_continue_statuses.indexOf(status)>-1){
            alert(`Message: PLEASE CHECK YOUR WHATSAPP` )
            window.location.href=`/login`

        }else{
            alert('Message: ACCOUNT NOT FOUND, PLEASE TRY AGAIN')
        }
        
    }


</script>



<style scoped>

.container{
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    width:100%;
    height: 100vh;
    max-height:100vh

}


.login{

    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    width:500px;
    padding: 1rem;

    background: rgba(255, 255, 255, 0.774);
}


label{
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    padding:3px;
}

</style>