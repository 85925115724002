<template>
    <div class="title">Attendance Report</div>

    <div class="query">
        <input v-model="data.query">
        <!-- <label>
            Start
            <input v-model="data.start" type="date">
        </label>
        <label>
            End
            <input v-model="data.end"  type="date">
        </label> -->

        <label>
            Month
            <select v-model="data.month">
                <option disabled>Select Month</option>
                <template v-for="month in 12">
                    <option :value="month">{{ month }}</option>
                </template>
            </select>
        </label>

        <label>
            Year
            <select v-model="data.year">
                <option disabled>Select Year</option>
                <template v-for="year in 10">
                    <option :value="data.initial_year+year"> {{ data.initial_year+year }} </option>
                </template>
            </select>
        </label>


        <button @click.stop="generate_attendance_report()">Generate Report</button>
    </div>

    <br>
    <br>
    <div class="subtitle">Attendance Report - {{data.start_text}} to {{ data.end_text }}</div>
    <div class="attendance-report-container">
        <div class="titles">
            <div class="left-side-cell">Group</div>
            <!-- <div class="left-side-cell instructor-column">Instructor</div> -->
            <div class="left-side-cell student-column">Student</div>
            <div class="left-side-cell schedule-column">Schedule</div>
            <!-- <div class="left-side-cell">Taken</div>
            <div class="left-side-cell">Missed</div> -->
        </div>
        <div class="days">
            <div class="day" v-for="day in data.days">
                {{ day }}
            </div>

        </div>
        <div class="students">
            <div class="student" v-for="student in data.students">
                <div class="student-info">
                    <div class="left-side-cell">
                        <template v-for="group in student.groups">
                            {{group.pk}} <br>
                        </template>
                    </div>
                    <!-- <div class="left-side-cell instructor-column">
                        <template v-for="group in student.groups">
                            <template v-for="instructor in group.instructor">
                                {{instructor.ui}} <br>

                            </template>
                        </template>
                    </div> -->
                    <div class="left-side-cell student-column">
                        {{ student.user.first_name }} {{ student.user.last_name }}
                    </div>
                    <div class="left-side-cell schedule-column">
                        <template v-for="group in student.groups">
                            {{group.schedule}} <br>
                        </template>
                    </div>
                    <!-- <div class="left-side-cell">taken</div>
                    <div class="left-side-cell">missed</div> -->
                </div>
                
                <div class="sdays">
                    <template v-for="day1 in data.days">
                        <div class="day">
                            <template v-for="attendance in student.days">
                                <template v-if="new Date(attendance.datetime).getDate()==day1">
                                    <div class="day" :class="{[attendance.student_confirmation]:true}">
                                        {{attendance.student_confirmation}}

                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                </div>
            </div>

        </div>


    </div>
    

</template>

<script setup>
import {reactive, onBeforeMount} from 'vue'
import axios from 'axios'


let data=reactive({
    query:"",
    month:"",
    year:2024,
    initial_year:2020,
    start:"",
    end:"",
    start_text:"start",
    end_text:"end",
    students:[],
    days:[],


})

async function generate_attendance_report(){
    
    let response=await axios.post('/attendance_report/attendance_report/',{query:data.query,year:data.year,month:data.month,start:data.start,end:data.end})
    data.students=response.data.students
    data.days=response.data.days
    data.start_text=response.data.start_text
    data.end_text=response.data.end_text
}


</script>


<style scoped>

    *{
        --width1:fit-content;
        --width2:calc(100vw - var(--width1));
        --height1:30px;
        --height2:40px;
    }
    

    .subtitle{
        width: 100%;
        background: #f3f1f1;
        border-bottom: 10px solid #EBEBEB;
        color:var(--black);
    }


    .attendance-report-container{
        display: grid;
        justify-content: start;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        overflow: auto;
        height: 80vh;

        grid-template-areas: "titles days"
                            "students students";

        grid-template-columns: var(--width1) var(--width2);
        grid-template-rows: var(--height1) auto;


        background:#EBEBEB;

        color:var(--black);
        text-align: center;
        text-justify: center;
                                                        

    }    
    
    

    .titles{
        grid-area: titles;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        font-weight: bolder;

        background: #f3f1f1;
        border-bottom: 5px solid #EBEBEB;


    }

    .days{
        grid-area:days;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        background: #f3f1f1;
        border-bottom: 5px solid #EBEBEB;




    }


    .left-side-cell{
        width: 60px;
        min-width: 50px;

    }

    .instructor-column{
        min-width: max-content;
        
    }

    .student-column{
        width: 150px;
        max-width: 150px;
    }

    .schedule-column{
        min-width:150px;
    }

    .students{
        grid-area: students;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-self: start;

        background: #f3f1f1;

        
        
    }

    .student{
        display: grid;
        grid-template-areas: "student-info sdays";
        grid-template-columns: var(--width1) var(--width2);
        width: 100%;
        overflow: hidden;
        height: 60px;

        
    }

    .student{
        height: var(--height2);
        border-bottom: 2px solid #EBEBEB;
        

    }


    .student-info{
        grid-area:student-info;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: var(--height2);


    }

    .sdays{
        grid-area:sdays;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;


    }
    

    .days .day{
        justify-content: center;
    }
    .day{
        height: 100%;
        width: 35px;
        min-width: 35px;
        overflow: hidden;
        white-space:nowrap;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: start;
        text-align: center;
        border-left: 2px solid #EBEBEB;
        padding: 2px;

    }

    .query{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }


</style>