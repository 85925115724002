<template>

  <div class="section meeting">
    <label>Meeting Name: 
      <input v-model="meeting_name">
      <button @click.stop="create_meeting(meeting_name)">Create Meeting</button>
    </label>
  </div>

  <div class="section">
    <Cards/>  
  </div>



  <div class="section">
    <Tickets/>
  </div>


  <div class="section">
    <Collection/>
  </div>

  <div class="section">
    <Instructors/>
  </div>

  <div class="section">
    <AttendanceReport/>
  </div>





    
  <div class="section">

    <InitialMessage/>
  </div>
    


<router-link to="/database">Database</router-link>



</template>

<script setup>
  import {ref, onBeforeMount, reactive, provide} from 'vue'
  import axios from 'axios'
  import Cards from '@/components/DashboardCard.vue'

  import Instructors from '@/components/Instructors.vue'

  import InitialMessage from '@/components/InitialMessage.vue'

  import Collection from '@/components/Collection.vue'
  import Tickets from '@/components/Tickets.vue'
  import AttendanceReport from '@/components/AttendanceReport.vue'




    let meeting_name=ref()
    let collection=reactive({})

    function create_meeting(meeting_name){
      window.location.href=`/meet/${meeting_name}`
    }



  



</script>



<style scoped>


  .meeting{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }





  .section{
    width:100vw;
    background:var(--blue);
    color:var(--white);
    overflow: hidden;

  }

  .section:nth-child(2n){
    background:var(--red);
  }



</style>