<template lang="html">

  <!-- <router-link to="/bad-attendances">
    <Card ref="bad_attendances"/>  
  </router-link> -->

    
    
    
  <div class="cards">
    <template v-for="card in cards_data">
      <div class="card">
        <span class='total'>{{ card.total }}</span>
        <span class="title">{{card.title}}</span>
      </div>
    </template>
  </div>

</template>

<script setup>
  import axios from 'axios'
  import {onBeforeMount,ref,reactive,inject} from 'vue'




  let cards_data=reactive({
      students:{},
      instructors:{},
      groups:{},
      bad_attendances:{},
      payroll1:{},
      payroll2:{}
    })




    onBeforeMount(async()=>{
    let response=await axios.post('/dashboard/get_cards/')
    let data=response.data
    
    cards_data.students={
                                title:'Students',
                                total:data.students

                              }

    cards_data.instructors={
                                title:'Instructors',
                                total:data.instructors

                              }

    cards_data.groups={
                                title:'Groups',
                                total:data.groups

                              }

    cards_data.bad_attendances={
                                title:'Bad Attendances',
                                total:data.bad_attendances

                              }

    cards_data.previous_payroll1={
                                title:'aPrev 1st Payroll',
                                total:data.previous_payroll1
                              }

    cards_data.previous_payroll2={
                                title:'aPrev 2nd Payroll',
                                total:data.previous_payroll2
                              }


    
    cards_data.payroll1={
                                title:'1st Payroll',
                                total:data.payroll1
                              }

    cards_data.payroll2={
                                title: '2nd Payroll',
                                total: data.payroll2
    }

    
      

  })










</script>

<style lang="css" scoped>

  .cards{
    display: grid;
    grid-template-columns: repeat(auto-fill,20rem);
    gap: 1rem;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .card{
    display: flex;
    flex-direction: column;
    background: var(--red3);
    color:var(--white);
    height: 10rem;
    width: 15rem;
    align-items:end;
    justify-content:center;
    border-radius: 1rem;
  }

  .total{
    font-weight: bold;
    font-size: xxx-large;
  }

  .title{
    font-size: xx-large;
  }

  .student{
    background: var(--blue1);
  }
  .instructor{
    background: var(--red1);
  }
  .group{
    background:var(--yellow1);
  }
















</style>
