<template>
  <!-- <TutorialScreen ref="tutorial_screen"/> -->
  
  <div class="container">
    <div class="title">
      {{ data.user.first_name }}'s Groups
    </div>
    <table class="groups">
      <thead>
        <th>Status</th>
        <th>Students</th>
        <th>Schedule</th>
        <!-- <th>Material</th> -->
        <th>Duration</th>
        <th>Videocall/Link</th>
        <th>Level</th>
      </thead>
      <tbody>
        <template v-if="data.groups">
          <template v-for="group in data.groups">
            <tr class="group" :class="{[group.status]:true }">
              <td class="status-cell">

                <template v-if="allowed_teachers.includes(data.user.first_name)">
                  <button @click.stop="open_status_window2(group)" class="status-button">
                    ONECLICK
                  </button>
                </template>
                <template v-else>
                  <StatusButtons :group="group" :user_id="data.user.id" :show="group.status_window_show" :hide="group.status_window_hide"/>
                  <button @click.stop="open_status_window(group)" class="status-button">
                    Status
                  </button>
                </template>
              </td>
              <td>
                <template v-if="group.student">
                    <template v-for="student in group.student">
                        {{ student.ui }}<br>
                    </template>
                </template>
              </td>
              <td> 
                <button @click.stop="show_request_schedule_change(group)">
                  {{ group.schedule }}
                </button>
              </td>

              <!-- <td>
                <button class="material-button">
                  Material
                </button>
              </td> -->
              <td class="duration-cell">
                <template v-if="group.status">
                  <template v-if="group.status.includes('confirmed')">
                    <DurationWindow :attendance="group.current_attendance" :show="group.current_attendance.show" :hide="group.current_attendance.hide"/>
                    <template v-if="group.current_attendance.duration">
                      <button @click.stop="open_duration_window(group.current_attendance)" class="duration-button">
                        {{ group.current_attendance.duration }}
                      </button>
                    </template>
                    
                  </template>
                </template>
              </td>
              <td class="link-cell">
                <template v-if="group.status">

                  <template v-if="group.status.includes('confirmed')">
                    <template v-if="group.current_attendance.link">
                      <button @click.stop="join_class(group)">
                        Enter Videocall
                      </button>
                      <button @click.stop="copy_to_clipboard(group)" class="copy-link">
                        Copy link
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    Confirm to get video link
                  </template>
                </template>
              </td>


              <td>
                <template v-if="group.student">
                    <template v-for="student in group.student">
                        {{ student.level }}
                    </template>
                </template>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <div class="container">
    <Availability/>
  
  </div>
  
  <div class="container">
    <Calendar :user="data.user"/>
  
  </div>

  <template v-if="allowed_teachers.includes(data.user.first_name)">
      <StatusWindow/>
    </template>
    <ScheduleChange/>

  

</template>


<script setup>
  import TutorialScreen from '@/components/TutorialScreen.vue'


  import {ref,reactive, provide, onMounted,onBeforeMount} from 'vue'
  import StatusButtons from '@/components/NormalStatusButtons.vue'
  import StatusWindow from '@/components/StatusWindow.vue'
  import DurationWindow from '@/components/DurationWindow.vue'
  import Calendar from '@/components/Calendar.vue'
  import Availability from '@/components/Availability.vue'
  import ScheduleChange from '@/components/ScheduleChange.vue'
  import {useRoute,useRouter} from 'vue-router'
  import axios from 'axios'
  import {useStore} from 'vuex'
  

  let store=useStore()
  let user=store.state.user
  let router = useRouter()
  let route=useRoute()


  let time_data=reactive({time:''})

  provide('time_data',time_data)

  let duration_data=reactive({duration:0})
  provide('duration_data',duration_data)


  let allowed_teachers=ref([
    'Ivan',
    'John'
  ])
  
  let data=reactive({
    groups:[],
    user:{},
  })

  provide('data',data)
  provide('availabilitydata',data)


  let status_data=reactive({
    group:{},
    show:false,
    hide:true,
  })

  provide('status_data',status_data)


  let video_link_data=reactive({
    link:'',
    group:{},
    user:{id:0},
  })

  provide('video_link_data',video_link_data)


  let request_schedule_change_data=reactive({
    show:false,
    group:{ui:''},
    new_schedules:[]
  })


  provide('request_schedule_change_data',request_schedule_change_data)





  onBeforeMount(async ()=>{

    data.user=user
    if (user.role.includes('Admin')){

      let user_id=route.query.user_id
      
      let response= await axios.post('/admin/get_user/',{user_id:user_id})
      user=response.data
      Object.assign(data.user,user)

    }
    get_groups()

    setInterval(()=>{
      if(route.name.includes('instructor-dashboard')){
        update_groups()
      }
    },10000)
    

  })


  async function get_groups(){

    let response = await axios.post('/instructors/get_groups/',{user_id:user.id})
    Object.assign(data.groups,response.data)

  }

  async function update_groups(){
    let response = await axios.post('/instructors/get_groups/',{user_id:user.id})
    let groups = response.data
    for ( let key in data.groups ){
      for (let element of groups){
        if (element.id==data.groups[key].id){
          let status_show=data.groups[key].status_window_show
          let status_hide=data.groups[key].status_window_hide
          let current_attendance=data.groups[key].current_attendance

          Object.assign(data.groups[key],element)
          Object.assign(data.groups[key].current_attendance,{show:current_attendance.show,hide:current_attendance.hide})

        }
      }

    }

  }



  async function open_status_window2(group){
    status_data.group=group
    // status_data.status=group.status
    status_data.user=data.user

    video_link_data.group=group
    video_link_data.user=data.user

    let response=await axios.post('/instructors/get_videolinks/',{user_id:data.user.id,group_id:group.id})

    video_link_data.videolinks=response.data

    

    let todayCDMX = new Date().toLocaleString('en-US', { timeZone: 'America/Mexico_City' });
    let dayOfWeekCDMX = new Date(todayCDMX).getDay();
    let days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    let day=days[dayOfWeekCDMX]


    let day_index=group.schedule.indexOf(day)
    let group_time=null
    console.log(group.schedule)
    console.log(day)
    console.log(day_index)
    if (day_index>-1){
      let colon_index = group.schedule.indexOf(':', day_index)
      if (colon_index != -1) {
      let start_index = colon_index - 2; 
      let end_index = colon_index + 3;    
      group_time = group.schedule.substring(start_index, end_index);
      }
    }

    console.log(group_time)
    time_data.time=group_time


    duration_data.duration=group.duration

    


    console.log(status_data)
    
    if (status_data.show){
      setTimeout(()=>{
        status_data.show=false
      },500)
      status_data.hide=true
    }else{
      status_data.show=true
      status_data.hide=false
    }
  }


  //   function open_status_window(group){
  //   status_data.group=group
  //   status_data.user=data.user
    
  //   console.log('opening oneclick')
  //   if (status_data.show){
  //     setTimeout(()=>{
  //       status_data.show=false
  //     },500)
  //     status_data.hide=true
  //   }else{
  //     status_data.show=true
  //     status_data.hide=false
  //   }
  // }
    
  function open_status_window(group){
    status_data.group=group
    status_data.user=data.user
    if (group.status_window_show){
      setTimeout(()=>{
        group.status_window_show=false
        
      },500)
      group.status_window_hide=true
      
    }else{
      group.status_window_show=true
      group.status_window_hide=false

    }

  }


  function open_duration_window(attendance){
    if (attendance.show){
      setTimeout(()=>{
        attendance.show=false
        
      },500)
      attendance.hide=true
    }else{
      attendance.show=true
      attendance.hide=false
    }
  }

  function show_request_schedule_change(group){
    console.log('open schedule change',group)
    request_schedule_change_data.show=true
    request_schedule_change_data.user=data.user
    request_schedule_change_data.group=group

    // let response=await axios.post('/instructors/request_schedule_change/',{group_id:group.id})
  }






  function join_class(group){
        let attendance_link=group.current_attendance.link
        window.location.href=attendance_link
        // router.push({ name: 'meet',params:{link:attendance_link}});
    }


  function copy_to_clipboard(group){
    navigator.clipboard.writeText(group.current_attendance.link);
    alert('Link copied')
  }



  




























function check_tutorial() {

  console.log('CHECKING TUTORIAL')
  console.log(instructor.value.user.tutorial)
  if (instructor.value.user.tutorial == true) {
    return
  } else {
    setTimeout(() => {
      playtutorial()
    }, 1000);
  }

}


class TutorialElement {
  constructor(element, positiondescription, message) {
    console.log('CONSTRUCTING TUTORIAL ELEMENT')
    this.element = element
    this.message = message
    this.positiondescription = positiondescription
  }

  focus(delay) {
    setTimeout(() => {

      let tutorial_elements = document.getElementsByClassName('tutorial-focus')
      Array.from(tutorial_elements).forEach(tutorial_element => {
        tutorial_element.classList.remove('tutorial-focus')
      })

      console.log(this.element)
      this.element.classList.add('tutorial-focus')
      tutorial_screen.value.message = this.message

      if (this.positiondescription == 'center') {
        this.midcoordinates()
        window.scrollTo({ top: this.position, behavior: "smooth" })
      } else {
        this.element.scrollIntoView({ behavior: "smooth" })

      }


    }, 5000 * delay)
  }

  midcoordinates() {
    this.top = this.element.getBoundingClientRect().top + window.pageYOffset


    let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    this.position = this.top - viewportHeight / 2

  }

}

let playtutorial = async () => {


  tutorial_screen.value.show = true

  let focus_elements = [

    new TutorialElement(document.getElementsByClassName(
      'group-title')[0],
      'center',
      'Click here to expand group information.'
    ),



    new TutorialElement(document.getElementsByClassName(
      'attendance-buttons')[0],
      'center',
      'Click here to confirm, cancel or change the confirmation of the class.'
    ),
    new TutorialElement(document.getElementsByClassName(
      'attendance')[0],
      'center',
      'Here you can view the confirmation status for your students and JOIN the class.'
    ),


  ]



  console.log(focus_elements)

  focus_elements.forEach((focus_element, index) => {

    focus_element.focus(index)

  })
  setTimeout(async () => {

    let tutorial_elements = document.getElementsByClassName('tutorial-focus')
    Array.from(tutorial_elements).forEach(tutorial_element => {
      tutorial_element.classList.remove('tutorial-focus')
      tutorial_screen.value.show = false
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })

    await axios.post('/instructors/update_tutorial/', { instructorid: instructor.value.id })



  }, 5000 * focus_elements.length)
}





</script>











<style scoped>


  .tutorial-focus{

  z-index:52;


  animation-name: tutorial-focus;
  animation-duration: calc(var(--home)/4);
  animation-timing-function: ease-in-out;
  /* animation-delay: calc(var(--home)*5); */
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode:both;



  }


  @keyframes tutorial-focus {
  0%{
    border: 10px solid var(--green);

  }
  100%{
    border: 10px solid var(--red);
  }

  }







  .container{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background:var(--darkblue);
    color: var(--white);
  }

  @media (width:600px) {
    .container{
      justify-content: start;
    }
  }

  .container:nth-child(2n){
    background: var(--darkred);
  }





  .groups{
    min-width:50%;
    max-width: 100%;

  }


  .group{
    height: fit-content;
  }

    




  .copy-link{
    background:var(--white);
    color:var(--blue);
  }

  .students{
    width: 100%;
    align-self:justify;
    justify-self:center;
  }

  td{
    padding: 5px;
  }



  @media (max-width:1000px) {

    .container{
      justify-content:start;
      overflow:auto;
    }

  }

  @media (max-width:1000px) {

    .groups{
      align-items:start;
      
    }

  }

  @media(max-width:1000px){
    .duration-cell{
      max-width: 200px;
    }
  }


</style>