<template>
    <div class="video-link-container">
        <button @click.stop="generate_interact_link()">Generate InterAct Link</button>
        <div>or</div>
        <div class="input-container">
            <input v-model="data.link" placeholder="paste custom link here">
            <button @click.stop="save_link_to_instructor(data.link)">Save</button>
        </div>
        <div>or</div>
        <div class="videolink-history">
            <template v-if="data.videolinks">
                <div class="links-container">
                    <div>Previously used group links</div>
                    <div @click.stop="select_link(group_link)" class="link" :class="{selected:group_link==data.link}" v-for="group_link in data.videolinks.group_links">
                        {{ group_link }}
                    </div>
                </div>
                <div class="links-container">
                    <div>Links saved by instructor</div>
                    <div @click.stop="select_link(instructor_link)" class="link" :class="{selected:instructor_link==data.link}" v-for="instructor_link in data.videolinks.instructor_links">
                        {{ instructor_link }}
                    </div>
                </div>


            </template>
        </div>
    </div>



</template>




<script setup>
    import {reactive,inject, createTextVNode} from 'vue'
    import axios from 'axios'



    let data=inject('video_link_data')
    


    async function generate_interact_link() {
        let response = await axios.post('/instructors/generate_interact_video_link/',{group_id:data.group.id,user_id:data.user.id})
        data.link=response.data.link
    }

    function select_link(link){
        data.link=link
    }

    async function save_link_to_instructor(link){
        console.log(link)
        let response= await axios.post('/instructors/save_link_to_instructor/',{link:link,user_id:data.user.id})
        let saved_videolink=response.data
        data.videolinks.instructor_links.push(saved_videolink.link)
    }


</script>



<style scoped>

    .video-link-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: min-content;
        height: auto;
    }


    .videolink-history{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: start;
        width: 100%;
        max-width: 100%;
        gap:5px;
    }
    

    .links-container{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        min-width: max-content;
        border: var(--border);
        border-color: var(--white);
        border-radius: 5px;
        padding: 5px;

        
    }

    .link{
        border: var(--border);
        border-radius: 5px;
        padding: 5px;
        background: var(--lightgray);
        color:var(--black);
        min-width: max-content;
        
    }

    .selected{
        background: var(--darkgreen);
    }




</style>